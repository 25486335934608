import { GET_SUBUSERS_BY_USER } from "../actions/Types";

let nullState = {
  subusers: [],
};

export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_SUBUSERS_BY_USER:
      return { ...state, subusers: payload };
    default:
      return state;
  }
};