export const ENABLE_REACTOUR = "ENABLE_REACTOUR";

//Porperty
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//User
export const GET_USER_INFO = "GET_USER_INFO";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_INFO_SELECT = "UPDATE_USER_INFO_SELECT";
export const UPDATE_USER_TODO_LIST = "UPDATE_USER_TODO_LIST";
export const UPLOAD_PROFILE = "UPLOAD_PROFILE";
export const GET_USER_FROM_TOKEN = "GET_USER_FROM_TOKEN";
//Jobboard
export const VALIDATE_SELECTIONUSER_MAIL = "VALIDATE_SELECTIONUSER_MAIL";
export const UPDATE_STATE_PRUEBA = "UPDATE_STATE_PRUEBA";
export const LIST_ALL_EMPRESAS_BOLSA = "LIST_ALL_EMPRESAS_BOLSA";
//Archivos
export const EXIST_FILE_CV = "EXIST_FILE_CV";

//Skills
export const GET_PATH_LOCATION = "GET_PATH_LOCATION";

/** SUBUSERS TYPES */
export const GET_SUBUSERS_BY_USER = "GET_SUBUSERS_BY_USER";

//NonMember
export const GET_NONMEMBER_FROM_EMAIL = "GET_NONMEMBER_FROM_EMAIL";

//EMPLEOS TYPES

/**EMPLEOS TYPES */
export const SET_TXTSEARCH = "SET_TXTSEARCH";
export const GET_EMPLEOS = "GET_EMPLEOS";
export const GET_ALL_EMPLOYMENTS = "GET_ALL_EMPLOYMENTS";
export const GET_ALL_EMPLOYMENTS_SUCCESS = "GET_ALL_EMPLOYMENTS_SUCCESS";
export const GET_ALL_EMPLOYMENTS_FAILED = "GET_ALL_EMPLOYMENTS_FAILED";
export const GET_ALL_FILTERED_EMPLOYMENTS = "GET_ALL_FILTERED_EMPLOYMENTS";
export const CREATE_EMPLEO = "CREATE_EMPLEO";
export const DUPLICATE_EMPLEO = "DUPLICATE_EMPLEO";
export const UPDATE_EMPLEO = "UPDATE_EMPLEO";
export const DELETE_EMPLEO = "DELETE_EMPLEO";
export const GET_EMPLEO_BY_ID = "GET_EMPLEO_BY_ID";
export const UPDATE_ESTADO_EMPLEO = "UPDATE_ESTADO_EMPLEO";



// POSTULACION

export const CREATE_POSTULACION = "CREATE_POSTULACION";
export const GET_POSTULACIONES_BY_EMPLEO = "GET_POSTULACIONES_BY_EMPLEO";
export const SET_POSTULACIONES_LOADING = 'SET_POSTULACIONES_LOADING';
export const UPDATE_CONTRATADO = "UPDATE_CONTRATADO";
export const UPDATE_DESCARGADO = "UPDATE_DESCARGADO";

// ALL

export const PERFIL_EXPERIENCIA = "PERFIL_EXPERIENCIA";
export const PERFIL_DATOS = "PERFIL_DATOS";


// EMPRESA 

export const CREATE_USER_INFO = "CREATE_USER_INFO";
export const LOADING_TABLE = "LOADING_TABLE";
export const STATE_TABLE = "STATE_TABLE";
export const GET_EMPLEOS_BY_EMPRESA = "GET_EMPLEOS_BY_EMPRESA";
export const DETAIL_EMPLEO = "DETAIL_EMPLEO";
export const STORE_FORM_VALUES = "STORE_FORM_VALUES";
export const CLEAR_FORM_VALUES = "CLEAR_FORM_VALUES"
export const EXPIRED_EMPLOYMENTS = "EXPIRED_EMPLOYMENTS";

//BANNERS

export const GET_BANNER_BY_BOLSA = "GET_BANNER_BY_BOLSA";