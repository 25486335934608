import {
    VALIDATE_SELECTIONUSER_MAIL,
    LIST_ALL_EMPRESAS_BOLSA,
} from "../actions/Types";

let nullState = {
    empleos: [],
    allPostulaciones: [],
    allPostulacionesLoading: false,
    allEmpresas: [],
    filteredEmployments: [],
    txtSearch: "",
    selectionUserMail: false,
    allEmpresasBolsa: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = nullState, { payload, type }) => {
    switch (type) {
        case VALIDATE_SELECTIONUSER_MAIL:
            return {
                ...state,
                selectionUserMail: payload,
            };
        case LIST_ALL_EMPRESAS_BOLSA:
            return {
                ...state,
                allEmpresasBolsa: payload,
            };    
        default:
            return state;
    }
};