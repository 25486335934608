import { combineReducers } from "redux";
import user from "./User";
import property from "./Property";
import jobboard from "./Jobboard";
import selection from "./Selection";
import subuser from "./Subuser";
import banners from "./Banner";

export default combineReducers({
    user,
    property,
    jobboard,
    selection,
    subuser,
    banners,
});