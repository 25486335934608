import { GET_BANNER_BY_BOLSA } from "../actions/Types";

let nullState = {
    banners: [],
};

export default (state = nullState, { payload, type }) => {
    switch (type) {
        case GET_BANNER_BY_BOLSA:
            return { ...state, banners: payload };
        default:
            return state;
    }
};