import axios from "axios";
import { baseUrl } from "../../constants";
import { setMessage } from "./Property";
import {
  GET_USER_INFO,
  ENABLE_REACTOUR,
  LOGOUT_USER,
  LOGIN_USER,
  EXIST_FILE_CV,
  REGISTER_USER,
  UPDATE_USER_INFO,
  UPDATE_USER_TODO_LIST,
  UPDATE_USER_INFO_SELECT,
  PERFIL_EXPERIENCIA,
  PERFIL_DATOS,
  UPLOAD_PROFILE,
  GET_PATH_LOCATION,
  GET_USER_FROM_TOKEN,
} from "./Types";

export const setPreloadedState = async () => {
  const response = await axios.get(baseUrl + "/api/users");
  let preloadedState = {};
  if (response.data.ok && response.data.user) {
    preloadedState = { user: { user: response.data.user } };
  }
  return preloadedState;
};

export const getUserInfo = () => async (dispatch) => {
  /**[TODO] */
  const response = await axios.get(baseUrl + "/api/users/info");
  const { ok, message, data } = response.data;

  if (ok) {
    dispatch({ type: GET_USER_INFO, payload: data });
    return data;
  } else {
    dispatch({ type: ENABLE_REACTOUR, payload: true });
    dispatch(setMessage(message));
  }
};

export const updateUserInfo = (formData) => (dispatch) => {
  console.log("FORM DATA", formData);

  return new Promise((resolve, reject) => {
    axios
      .put(baseUrl + "/api/users/info", formData)
      .then((response) => {
        const { ok, message, data } = response.data;

        if (ok) {
          dispatch(setMessage("Se agrego tus datos correctamente"));
          dispatch({ type: UPDATE_USER_INFO, payload: data });
          resolve(data); // resuelve la promesa cuando se completa con éxito
        } else {
          dispatch(setMessage(message));
          reject(message); // rechaza la promesa cuando hay un error
        }
      })
      .catch((error) => {
        dispatch(setMessage("Error actualizando la información del usuario"));
        reject(error);
      });
  });
};

export const updateUserInfoSelection =
  (formData, module = "") =>
  async (dispatch) => {
    const response = await axios.put(baseUrl + "/api/users/info", formData);
    const { ok, message, data } = response.data;
    if (ok) {
      dispatch({ type: UPDATE_USER_INFO_SELECT, payload: data });
      if (module === PERFIL_EXPERIENCIA || module === PERFIL_DATOS) {
        dispatch(saveTodoStep(module));
      }
      dispatch(setMessage("Se actualizaron tus datos correctamente"));
    } else {
      dispatch(setMessage(message));
    }
  };

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_USER });
    const response = await axios.delete(baseUrl + "/api/users");
    console.log(response.data);
    const { ok, message } = response.data;
    if (ok) {
      // dispatch({ type: LOGOUT_USER });
    } else {
      // console.log(ok, 'fallo de alguna forma')
      // dispatch(setMessage(message));
    }
  } catch (error) {
    dispatch(setMessage(error.message));
    console.log("ERROR: -->", error);
    dispatch({ type: LOGOUT_USER }); // Esto es opcional, dependiendo de lo que quieras hacer si falla la petición a la API.
  }
};

export const loginUser =
  (formData, retorno = false) =>
  async (dispatch) => {
    const response = await axios.post(baseUrl + "/api/users/login", formData);
    console.log("loginUser -> response", response);
    const { ok, message, user } = response.data;

    if (ok) {
      dispatch({ type: LOGIN_USER, payload: user });
      dispatch(existCV());
      dispatch(setMessage("Bienvenido " + user.username));
      dispatch({ type: ENABLE_REACTOUR, payload: false });
      if (retorno) return user;
    } else {
      dispatch(setMessage(message));
      if (retorno) return ok;
    }
  };

export const existCV = (tipo) => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/upload/existFileUCH", tipo);
  const { ok, message, data } = response.data;
  if (ok) {
    dispatch({ type: EXIST_FILE_CV, payload: data });
    dispatch(setMessage(message));
  } else {
    dispatch(setMessage(message));
  }
};

export const loginUserRecruitment = async (formData) => {
  const response = await axios.post(baseUrl + "/api/users/login", formData);
  console.log("loginUser -> response", response);
  return response.data;
};

export const registerUser =
  (formData, retorno = false) =>
  async (dispatch) => {
    const response = await axios.post(
      baseUrl + "/api/users/register",
      formData
    );

    //console.log("REGISTER", response);
    const { user, ok, message } = response.data;
    if (ok) {
      dispatch({ type: REGISTER_USER, payload: user });
      dispatch(existCV());
      if (retorno) return user;
    } else {
      dispatch(setMessage(message));
      if (retorno) return null;
    }
  };

export const registerUserRecruitment = async (formData) => {
  const response = await axios.post(baseUrl + "/api/users/register", formData);
  return response.data;
};

export const forgotPassword = (formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/users/forgot-pass",
    formData
  );
  console.log(response);
  console.log(formData);
  const { message } = response.data;
  dispatch(setMessage(message));
};

export const forgotUser = (formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/users/forgot-user",
    formData
  );
  const { message } = response.data;
  dispatch(setMessage(message));
};

export const uploadCurriculumVitae = (file) => async (dispatch) => {
  console.log("entro a la funcion");
  try {
    const response = await axios.post(
      baseUrl + "/api/v2/filesuch/uploads/curriculumVitae",
      file
    );
    console.log("enviando cv");
    if (response.data.ok) {
      dispatch(setMessage("Archivo CV subido satisfactoriamente."));
      return response.data; // Asegúrate de devolver algo aquí
    } else {
      dispatch(setMessage(response.message));
      return null; // O aquí
    }
  } catch (error) {
    if (error.response) {
      dispatch(setMessage(`${error.response.data.message}`));
      return error.response.data; // Y también aquí
    } else {
      dispatch(setMessage("Error al subir CV, comuníquese con HCP"));
      return null; // Y aquí
    }
  }
};

export const getUserEmailVerifiedStatus = async (userId) => {
  try {
    const response = await axios.get(
      baseUrl + "/api/v2/verify-email/user/" + userId
    );
    return response.data;
    /*  const { ok, message, verified } = response.data;
    if (ok) {
      return verified;
    } else {
      dispatch(setMessage(message));
      return response;
    } */
  } catch (error) {
    /* dispatch(
      setMessage("Error al validar email verificado de usuario  " + error)
    ); */
    return { ok: false, message: "Error al verificar el estado del correo" };
    // throw error;
  }
};

export const saveTodoStep = (name) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/v2/users/steps/saveTodo", {
    step: name,
  });
  const { ok, message, data } = response.data;
  console.log(response);
  if (ok) {
    dispatch({ type: UPDATE_USER_TODO_LIST, payload: data });
  } else {
    dispatch(setMessage(message));
  }
};

export const uploadProfile = (file) => async (dispatch) => {
  console.log("subiendo");
  try {
    const response = await axios.post(baseUrl + "/api/upload/profile", file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { ok, message, data } = response.data;
    if (ok) {
      dispatch({ type: UPLOAD_PROFILE, payload: ok });
      dispatch(setMessage("Se Subio la imagen de perfil"));
    } else {
      dispatch(setMessage(message));
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveHistory = (path) => async (dispatch) => {
  dispatch({ type: GET_PATH_LOCATION, payload: path });
  const response = await axios.post(baseUrl + "/api/v2/tracking", {
    path: path,
  });
  const { ok } = response.data;

  if (ok) {
  }
};

export const getUserFromEmailToken = (token) => async (dispatch) => {
  try {
    const response = await axios.get(baseUrl + "/api/v2/verify-email/" + token);

    const { ok, message } = response.data;
    if (ok) {
      dispatch(setMessage(message));
      return response;
    } else {
      dispatch(setMessage(message));
      return response;
    }
  } catch (error) {
    dispatch(setMessage("Error al verificar correo: " + error));
    // throw error;
  }
};

export const getUserFromToken = (token) => async (dispatch) => {
  const response = await axios.get(baseUrl + "/api/users/reset/" + token);
  const { ok, message, data } = response.data;

  if (ok) {
    dispatch({ type: GET_USER_FROM_TOKEN, payload: data });
  } else {
    dispatch(setMessage(message));
  }
};

export const resetPassword = (token, formData) => async (dispatch) => {
  const response = await axios.post(
    baseUrl + "/api/users/reset/" + token,
    formData
  );
  const { ok, message } = response.data;

  if (ok) {
    dispatch(setMessage("Se cambio la contraseña"));
  } else {
    dispatch(setMessage(message));
  }
  return { ok, message };
};

export const updateBolsa = (formData) => (dispatch) =>{
  return new Promise((resolve, reject) =>{
    axios.put(baseUrl + "/api/v2/user-info/update-bolsa", formData)
  })
  
}

export const getReportAlumnosBolsa = () => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/v2/analytics/get-report-alumnos");
  const { ok, message, data } = response.data;
  console.log(response);
  if (ok) {
    return data
  } else {
    dispatch(setMessage("Ocurrio un error"));
  }
}

export const getReportsBolsa = (formData) => async (dispatch) => {
  const response = await axios.post(baseUrl + "/api/v2/users/reportes/bolsa-laboral", formData);  
}