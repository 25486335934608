import {
  GET_ALL_EMPLOYMENTS,
  GET_ALL_EMPLOYMENTS_FAILED,
  GET_ALL_EMPLOYMENTS_SUCCESS,
  GET_EMPLEOS_BY_EMPRESA,
  STATE_TABLE,
  LOADING_TABLE,
  DETAIL_EMPLEO,
  CREATE_EMPLEO,
  UPDATE_EMPLEO,
  STORE_FORM_VALUES,
  CLEAR_FORM_VALUES,
  GET_EMPLEO_BY_ID,
  DELETE_EMPLEO,
  GET_POSTULACIONES_BY_EMPLEO,
  SET_POSTULACIONES_LOADING,
  EXPIRED_EMPLOYMENTS,
} from "../actions/Types";

let nullState = {
  empleos: [],
  allEmpleosFavoritos: [],
  allRecommendedJobs: [],
  pruebasTerminadas: [],
  allPostulaciones: [],
  allPostulacionesLoading: false,
  errorPostulaciones: null,
  allEmpresas: [],
  allEstudios: [],
  experienciaCliente: {},
  postuloEmpleo: {},
  empleo: {},
  isLoading: false,

  //v2
  detalleEmpleo: null,
  allEmpleosEmpresa: [],
  allEmployments: [],
  expiredEmployments: [],
  filteredEmployments: [],
  txtSearch: "",
  plan: null,
  selectionUserMail: false,
  platformScore: 1,
  //evaluaciones
  evaluaciones: [],
  evaluacion: {},
  allInvitacionesEvaluacion: [],
  formValues: {},
};
// eslint-disable-next-line default-case, import/no-anonymous-default-export
export default (state = nullState, { type, payload }) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case GET_ALL_EMPLOYMENTS:
      return {
        ...state,
        allEmployments: payload,
        isLoading: true,
      };
    case EXPIRED_EMPLOYMENTS:
      return {
        ...state,
        expiredEmployments: payload,
      }  
    case GET_ALL_EMPLOYMENTS_SUCCESS:
      return {
        ...state,
        allEmployments: payload,
        isLoading: false,
      };
    case GET_ALL_EMPLOYMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_EMPLEOS_BY_EMPRESA:
      return {
        ...state,
        allEmpleosEmpresa: payload,
      };
    case STATE_TABLE:
      return {
        ...state,
        stateTable: payload,
      };
    case LOADING_TABLE: // Este es el nuevo caso que debes añadir
      return {
        ...state,
        isLoading: payload,
      };
    case DETAIL_EMPLEO:
      return {
        ...state,
        detalleEmpleo: payload,
      };
    case CREATE_EMPLEO:
      return {
        ...state,
        empleos: [payload, ...state.empleos],
      };
    case UPDATE_EMPLEO:
      const updatedEmpleos = state.allEmpleosEmpresa.map((e) =>
        e._id === payload._id ? payload : e
      );
      console.log(updatedEmpleos, "data actualizada");
      return {
        ...state,
        allEmpleosEmpresa: updatedEmpleos,
      };

    case DELETE_EMPLEO:
      return {
        ...state,
        allEmpleosEmpresa: state.allEmpleosEmpresa.filter(
          (empleo) => empleo._id !== payload._id
        ),
      };
    case STORE_FORM_VALUES:
      return {
        ...state,
        formValues: payload,
      };
    case CLEAR_FORM_VALUES:
      return {
        ...state,
        formValues: {},
      };
    case GET_EMPLEO_BY_ID:
      return {
        ...state,
        detalleEmpleo: payload,
      };
    case GET_POSTULACIONES_BY_EMPLEO:
      return {
        ...state,
        postulaciones: payload,
      };
    case SET_POSTULACIONES_LOADING:
      return {
        ...state,
        allPostulacionesLoading: payload,
      };

    default:
      return state;
  }
};
