import axios from "axios";
import { baseUrl } from "../../constants";
import {
    SET_MESSAGE,
    CLEAR_MESSAGE,
} from "./Types";

export const setMessage = (message) => ({
    type: SET_MESSAGE,
    payload: message,
});

export const clearMessage = () => async (dispatch) => {
    dispatch({ type: CLEAR_MESSAGE });
};