import {
    SET_MESSAGE,
    CLEAR_MESSAGE,
} from "../actions/Types";

let nullState = {
    message: null,
    loading: false
};

export default (state = nullState, { payload, type }) => {
    switch (type) {
        case SET_MESSAGE:
            return {
                ...state,
                message: payload
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: null
            };
        default:
            return state;
    }
};