import {
  GET_USER_INFO,
  ENABLE_REACTOUR,
  LOGOUT_USER,
  LOGIN_USER,
  EXIST_FILE_CV,
  REGISTER_USER,
  UPDATE_USER_INFO_SELECT,
  UPDATE_USER_INFO,
  UPDATE_USER_TODO_LIST,
  UPLOAD_PROFILE,
  GET_USER_FROM_TOKEN,
} from "../actions/Types";

let nullState = {
  user: {
    image: null,
  },
  notifications: [],
  userInfo: null,
  cv: null,
  cvDetails: null,
  profile: false,
  base64: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = nullState, { payload, type }) => {
  switch (type) {
    case GET_USER_INFO:
      return {
        ...state,
        userInfo: payload,
      };
    case UPDATE_USER_INFO:
    case UPDATE_USER_INFO_SELECT:
      return {
        ...state,
        userInfo: payload,
      };
    case LOGIN_USER:
    case GET_USER_FROM_TOKEN:
    case REGISTER_USER:
      return {
        ...state,
        user: payload,
      };
    case UPLOAD_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          image: payload,
        },
      };

    case ENABLE_REACTOUR:
      return {
        ...state,
        enableReactTour: payload,
      };
    case LOGOUT_USER:
      return nullState;
    case EXIST_FILE_CV:
      return {
        ...state,
        cv: payload,
      };
    case UPDATE_USER_TODO_LIST:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          todoList: payload,
        },
      };
    default:
      return state;
  }
};
